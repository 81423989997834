@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";

$grid-breakpoints: (
  xs: 0,
  sm: 600px,
  md: 960px,
  lg: 1280px,
  xl: 1920px
);

$container-max-widths: (
  sm: 600px,
  md: 860px,
  lg: 1180px,
  xl: 1250px
);

@import "node_modules/bootstrap/scss/bootstrap";