/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700|Roboto:300,400,500,700,900&display=swap");
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "./assets/styles/variables.scss";

*:focus {
	outline: none;
}

* {
	margin: 0;
	padding: 0;
}

textarea:focus,
input:focus,
input.form-control,
button:focus {
	outline: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

body {
	font-family: "Roboto", sans-serif;
}

h2 {
	font-family: "Roboto Condensed", sans-serif;
	font-size: 21px;
	font-weight: 700;
	text-transform: uppercase;
}

a {
	cursor: pointer;
}

textarea {
	resize: none;
}

.cursor-pointer{
	cursor: pointer;
}

.noselect {
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Safari */
	-khtml-user-select: none;
	/* Konqueror HTML */
	-moz-user-select: none;
	/* Old versions of Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
	/* Non-prefixed version, currently */
}

input.se-input {
	border-radius: 0;
	height: 45px;
	background-color: transparent;
	border: 1px solid #d8d8d8;
	border-color: #d8d8d8;
	box-shadow: none;

	&:hover,
	&:focus,
	&:active {
		border-color: #d8d8d8;
		border-top-color: #d8d8dd;
	}
}

.no-uppercase {
	text-transform: none !important;
}

textarea.se-textarea {
	border-radius: 0;
	background-color: transparent;
	padding: 10px;
	width: 100%;
}

.se-panel,
.se-border {
	border: 1px solid #d8d8d8;
	border-radius: 0;
}

.se-table {
	border-bottom: 1px solid #d8d8d8;
}

.se-table th {
	text-transform: uppercase;
	border: none;
}

.se-table tr {
	border: 1px solid #d8d8d8;
	border-bottom: transparent;
}

.se-table-header,
.se-table-row {
	display: flex;
	flex-wrap: wrap;
}

.se-table-row {
	border: 1px solid #d8d8d8;
	border-bottom: 0;
}

.se-table-col {
	padding: 15px;
	border-bottom: transparent;
}

.se-table-row .se-table-col.sm {
	flex: 1 1 0;
	width: 5%;
}

.se-table-row .se-table-col.md {
	flex: 2 2 0;
	width: 18%;
}

.se-table-row .se-table-col.lg {
	flex: 3 3 0;
	width: 24%;
}

.se-table-flex {
	border-bottom: 1px solid #d8d8d8;
}

.se-table-header .se-table-col {
	font-weight: 600;
	text-transform: uppercase;
	border: none;
}

.alert {
	border-radius: 0;
}

.bold {
	font-weight: 600;
}

ngb-toast.bg-success {
	background-color: #d4edda !important;
	color: #155724 !important;
	border-color: #c3e6cb !important;
}

ngb-toast.bg-danger {
	background-color: #f8d7da !important;
	color: #9b5e64 !important;
	border-color: #f5c6cb !important;
}

ngb-toast.bg-warning {
	color: #856404 !important;
	background-color: #fff3cd !important;
	border-color: #ffeeba !important;
}

ngb-toast {
	border-radius: 0 !important;
	box-shadow: none !important;
}

app-toast.ngb-toasts {
	right: 5px;
	top: 75px;
	z-index: 99999999999;
}

h3.se-title {
	font-weight: 200;
	margin-bottom: 30px;
}

.bold,
.se-title.bold {
	font-weight: 500;
}

.ttu {
	text-transform: uppercase;
}

.underlined:after {
	content: "";
	width: 100px;
	height: 3px;
	background-color: #01060a;
	display: block;
	border-radius: 5px;
	margin: 15px 0 40px;
}

.clickable {
	cursor: pointer;
}

.load-more {
	width: 200px;
	display: block;
	margin-top: 20px !important;
}

.max-flex-width-100 {
	max-width: 100%;
	flex: 0 0 100%;
}

@media screen and (max-width: 960px) {
	.se-table-header .se-table-col {
		font-size: 12px;
	}
}

.se-table-row.article-id-col {
	flex: 3 3 0;
	width: 24%;
	max-width: 310px;
}

.help-button {
	margin: auto;
	height: 25px;
	width: 25px;
	margin-left: 10px;
	background-color: #fff;
	border: 1px solid #cccccc;
	color: #cccccc;
}

.card {
	border-radius: 0px;
}

.card-footer {
	background-color: #fff;
}

label {
	margin-bottom: 0;
}

.toggle-invisible-button {
	display: block;
	width: 100%;
	position: absolute;
	text-align: left;
	height: 100%;
	left: 0;
	top: 0;
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;

	&:hover {
		background-color: transparent;
	}

}

.form-control,
.ng-select .ng-select-container {
	border-radius: 0px;
}

.accordion>.card {
	overflow: initial;
}

.accordion>.card>.card-header {
	border-bottom: 1px solid #d8d8d8;
	position: relative;
}

ngb-accordion>.card {
	margin-bottom: 20px;
}

.ng-dropdown-panel {
	border-radius: 0 !important;
}

.ng-select.ng-select-single .ng-select-container {
	height: 38px;
}

.ng-select .ng-clear-wrapper .ng-clear {
	margin-top: 5px;
}

.border-none {
	border: none !important;
}

.nav-pills .nav-link {
	border-radius: 0px;
	border: 1px solid #d8d8d8;
	border-top: none;
	color: #000 !important;
	font-weight: 500;

	&.active {
		background-color: rgba(0, 0, 0, 0.03);
	}
}

ng-select.ng-invalid {
	.ng-select-container {
		border: 1px solid rgba(255, 26, 26, 0.822);
	}
}

.border-left {
	border-left: 1px solid #d8d8d8 !important;
}

.border-right {
	border-right: 1px solid #d8d8d8 !important;
}

.border-top {
	border-top: 1px solid #d8d8d8 !important;
}

.border-bottom {
	border-bottom: 1px solid #d8d8d8 !important;
}

.border {
	border: 1px solid #d8d8d8 !important;
}

.modal-open .modal {
	z-index: 9999999;
}

.modal-content {
	top: 100px;
	border-radius: 0px;
}

ngb-modal-window.image-link-modal-window {
	z-index: 9999999 !important;
}

ngb-modal-backdrop.image-link-modal-backdrop {
	z-index: 9999999 !important;
}

ngb-modal-backdrop.close-confirm-modal-backdrop {
	opacity: 0 !important;
}

i.error-circle {
	color: rgba(255, 26, 26, 0.822);
	line-height: 18px;
	font-size: 18px;
}

.toggle-invisible-button {
	z-index: 999;
}

.custom-modal-materials {
	.modal-dialog {
		max-width: 95%
	}
  }


